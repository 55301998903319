<template>
  <div class="cycle-overview-embed">
    <div v-if="!cycle_loading && !stock_loading">
      <sampling :selected-block="selectedBlock" v-if="selectedAction === 'Sampling'"
                hide-action
                hide-chart
                :date-label="dateLabel"
                :start-date="date"
                @loaded="sendMessage"/>
      <death-count v-if="selectedAction === $t('death.death')"
                   hide-action
                   hide-chart
                   :date-label="dateLabel"
                   @loaded="sendMessage"/>
    </div>

    <div v-if="sent" class="sent"></div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
// import SgTab from "@/components/Sg/SgTab";
// import SgSelect from "@/components/Sg/SgSelect";
// import StockCycle from "@/components/Farm/Overview/StockCycle";
// import InputStartEndCycle from "@/components/Farm/Sidebar/InputStartEndCycle";
// import Harvest from "@/components/Farm/Overview/Harvest";
import Sampling from "@/components/Farm/Overview/Sampling";
// import Disease from "@/components/Farm/Overview/Disease";
import DeathCount from "@/components/Farm/Overview/DeathCount";
import {toPng} from "html-to-image";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "CycleOverview",
  components: {
    DeathCount,
    // Disease,
    Sampling,
    // Harvest,
    // StockCycle,
    // SgSelect,
    // SgTab,
  },
  data() {
    return {
      selectedAction: this.$t('cycle.cycle') + ' ' + this.$t('stock.stock'),
      selectedBlock: null,
      selectedPond: null,
      selectedCycle: null,
      filteredCycles: [],
      cycle_loading: false,
      stock_loading: false,

      cycleModal: false,
      type: null,

      messageTimeout: null,

      sent: false,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
      'hasCycle',
      'hasStock',
    ]),

    timediff () {
      return parseInt(this.$route.query.timediff)
    },

    actions() {
      let res = []

      if (this.canViewStock) res.push(this.$t('cycle.cycle') + ' ' + this.$t('stock.stock'))
      if (this.canViewHarvest) res.push(this.$t('harvest.harvest'))
      if (this.canViewSampling) res.push('Sampling')
      if (this.canViewDisease) res.push(this.$t('disease'))
      if (this.canViewDeath) res.push(this.$t('death.death'))

      return res
    },
    ...mapGetters('farm', [
      'canViewStock',
      'canViewHarvest',
      'canViewSampling',
      'canViewDisease',
      'canViewDeath',
      'canViewCycle',
      'canViewWaterQuality',
    ]),

    selectedDate: {
      get() {
        return this.$store.state.farm.analysis.date
      },
      set(value) {
        this.$store.dispatch('farm/updateSelectedFarmStatusDate', value)
        this.refreshData()
      },
    },

    date() {
      let time = parseInt(this.$route.query.date || new Date().getTime())
      return this.$moment(time).startOf('day').valueOf()
    },

    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },

    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },

    defaultTab() {
      return this.$route.query.tab
    },

    defaultBlock() {
      return this.$route.query.block_id
    },

    farm_id () {
      return this.selectedFarm.farm_id
    },

    uid() {
      return this.$route.query.pu_id
    },

    guid() {
      return `sgara${this.farm_id}`
    },

    ...mapState('user', [
      'cometChatUser',
      'cometChatLoggedIn',
    ]),

    dateLabel() {
      return this.$dateFormat(this.$addMinute(this.date, this.timediff))
    },

    tag () {
      switch (this.defaultTab) {
        case 'sampling':
          return 'Sampling'
        case 'death':
          return 'Kematian'
      }
      return null
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.init()
    },
  },
  created() {
    switch (this.defaultTab) {
      case 'sampling':
        this.selectedAction = 'Sampling'
        break
      case 'death':
        this.selectedAction = this.$t('death.death')
        break
    }

    if (this.date) this.selectedDate = new Date(this.date)
    this.chatLogin().then(() => {
      this.init()
    })
  },
  methods: {
    init() {
      this.cycle_loading = true
      this.stock_loading = true
      this.$loading()

      Promise.all(
          [
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getCycleOverview', {pond: e})
            }),
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getStockOverview', e)
            }),
            this.$store.dispatch('farm/getFarmInfo'),
          ]
      ).then(res => {
        if (this.selectedFarm.blocks && this.selectedFarm.blocks.length && ((this.selectedBlock && !this.selectedFarm.blocks.find(e => e.block_id === this.selectedBlock.block_id)) || !this.selectedBlock)) {
          this.selectedBlock = this.selectedFarm.blocks.find(e => e.block_id === this.defaultBlock) || this.selectedFarm.blocks[0]
        }
        this.refreshData()
      }).finally(() => {
        this.cycle_loading = false
        this.stock_loading = false
        this.$loading(false)
      })
    },
    refreshData() {
      this.loaded = false

      let date = this.$timestamp(this.selectedDate)

      let ponds = []

      if (this.selectedBlock) {
        ponds = this._.cloneDeep(this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)).map(e => {
          e.selected_cycle = e.cycles ? e.cycles.find(f => {
            return date >= f.start_date && (f.end_date === 0 || date <= f.end_date)
          }) : null
          return e
        })
      }

      this.$store.commit('farm/UPDATE_OVERVIEW_PONDS', ponds)

      this.$nextTick(() => {
        this.loaded = true
      })
    },

    refreshOverview() {
      this.$store.dispatch('farm/refreshOverview')
    },

    openCycleForm(type) {
      this.type = type
      this.cycleModal = true
    },

    sendMessage() {
      if (this.messageTimeout) clearTimeout(this.messageTimeout)
      this.messageTimeout = setTimeout(() => {
        toPng(document.querySelector('.cycle-overview-embed .b-table .table')).then(url => {
          let file = this.$dataURLtoFile(url, 'example.png')

          let receiverID = this.guid;
          let messageType = CometChat.MESSAGE_TYPE.IMAGE;
          let receiverType = CometChat.RECEIVER_TYPE.GROUP;
          let mediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType);

          let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + this.tag + " tanggal " + this.dateLabel;

          mediaMessage.setTags([this.tag])
          mediaMessage.setCaption(messageText)

          CometChat.sendMediaMessage(mediaMessage).then(
              message => {
                this.sent = true
                console.log("Media message sent successfully", message);
              }, error => {
                console.log("Media message sending failed with error", error);
              }
          );

        }).catch(err => {
          console.log(err)
        })
      }, 1000)
    },

    chatLogin() {
      return new Promise((resolve, reject) => {
        this.$store.commit('user/UPDATE_COMET_CHAT_STATUS')
        this.$store.commit('user/UPDATE_COMET_CHAT_USER')
        CometChat.login(this.uid, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
            .then(async res => {
              this.$store.commit('user/UPDATE_COMET_CHAT_STATUS', true)
              this.$store.commit('user/UPDATE_COMET_CHAT_USER', res)
              resolve(res)
            })
            .catch(err => {
              reject(err)
              // if (err.code === "ERR_UID_NOT_FOUND") this.chatCreateUser(this.user)
            })
      })
    },
  }
}
</script>
